<script>
    import * as courses from "../../courses-data.json"
    const convertToCurrency = (currencySymbol, value) => {
        if (value === 0) {
            return 'Free'
        } 
        return currencySymbol + value.toFixed(2)
    };
    const formatCurrency = (courseArray) => {
        courseArray = JSON.parse(JSON.stringify(courseArray));
        for (let i = 0; i < courseArray.length; i++) {
            const course = courseArray[i];
            // add costHigh when necessary
            course.costLow = convertToCurrency('$', course.costLow)
        }
        return courseArray
    }

    let name = ""
    let cost = ""
    let time = "Time Commitment"
    
  const filterArray = (arr, name, cost, time) => {
    arr = JSON.parse(JSON.stringify(arr));
    if(cost==="" || cost === null) {
        cost = 9999
    }
    return arr.filter(item => {
      if(typeof item.costLow === 'string') {
         item.costLow = parseFloat(item.costLow.replace('$', ''))
      }
    const nameMatch = name ? item.name.toLowerCase().includes(name.toLowerCase()) : true;
    const costMatch = cost !== undefined ? item.costLow <= cost : true;
    const timeMatch = ["low", "medium", "high"].includes(time) ? item.time === time : true;
    return nameMatch && costMatch && timeMatch;
  });
}

let filteredCourses = []

$: {
    filteredCourses = filterArray(courses.default, name, cost, time)
    filteredCourses = formatCurrency(filteredCourses)
}
    
</script>
  
<style>

    h1 {
        text-align: center;
        font-size: 3rem;
    }
    .container {
        width: 80%;
        margin: auto;
    }

    .row, a {
        width: 100%;
        max-width: 1000px;
        max-width: 1200px;
        background-color: #eff9f0;
        display: flex;
        border-radius: 15px;
        margin: 15px 0 15px 0;
        padding: 5px;
    }

    a:hover {
        box-shadow: 10px 10px 0 #19006d;
    }

    .image {
        max-height: 400px;
    }

    .image img {
        width: 90%;
    }

    .text, .image {
        width: 50%;
    } 

    .text {
        text-align: center;
    }

    .text h2 {
        font-size: 2rem;
    }

    .text p {
        font-size: 1.5rem;
    }

    .image {
        display: flex;
        justify-content: center;
    }

    a {
        text-decoration: none;
        color: inherit;
    }

    .form-wrapper {
        display: flex;
        justify-content: center;
        margin-bottom: 40px;
    }

    .search-icon {
        margin-right: 8px;
    }

    ::-webkit-input-placeholder {
        color: #000;
    }

    :-ms-input-placeholder { 
        color: #000;
    }

    ::placeholder {
        color: #000;
    }

    /* Media query for formatting images on smaller screens */
    @media (max-width: 1000px) {
        .row {
            flex-direction: column;
        }

        .text, .image {
        width: 100%;
        } 
    }

    /* Media query for tablets and below */
    @media (max-width: 768px) {
        h1 {
            font-size: 2.5rem;
        }

        .form-wrapper {
            flex-direction: column;
            align-items: center;
        }

        form {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 10px;
        }

        input, select {
            margin-bottom: 10px;
            width: 90%;
            max-width: 300px; /* Limit the width of the form elements */
        }

        p, .name {
            width: 100%;
            margin-top: 10px;
        }

        .search-icon {
            margin-bottom: 10px;
        }
    }

</style>
  
<h1>Find a course</h1>
<div class="form-wrapper">
    <svg class="search-icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="30" zoomAndPan="magnify" viewBox="0 0 375 374.999991" height="30" preserveAspectRatio="xMidYMid meet" version="1.0"><defs><clipPath id="d371c700fc"><path d="M 21 19.039062 L 355.789062 19.039062 L 355.789062 354 L 21 354 Z M 21 19.039062 " clip-rule="nonzero"/></clipPath></defs><g clip-path="url(#d371c700fc)"><path fill="#000000" d="M 139.507812 265.289062 L 57.027344 347.738281 C 48.863281 355.902344 35.601562 355.902344 27.433594 347.734375 C 19.269531 339.566406 19.273438 326.304688 27.4375 318.136719 L 109.863281 235.742188 C 93.28125 213.191406 83.480469 185.339844 83.480469 155.214844 C 83.480469 80.058594 144.5 19.039062 219.65625 19.039062 C 294.8125 19.039062 355.828125 80.058594 355.828125 155.214844 C 355.828125 230.371094 294.8125 291.390625 219.65625 291.390625 C 189.703125 291.390625 162 281.699219 139.507812 265.289062 Z M 219.65625 60.894531 C 167.597656 60.894531 125.332031 103.15625 125.332031 155.214844 C 125.332031 207.273438 167.597656 249.539062 219.65625 249.539062 C 271.710938 249.539062 313.976562 207.273438 313.976562 155.214844 C 313.976562 103.15625 271.710938 60.894531 219.65625 60.894531 Z M 219.65625 60.894531 " fill-opacity="1" fill-rule="evenodd"/></g></svg>
    <form>
        <input type="text" bind:value={name} placeholder="Name">
        <input type="number" bind:value={cost} placeholder="Max Cost">
        <select type="text" bind:value={time}>
            <option value="Time Commitment">Time Commitment</option>
            <option value="low">Low</option>
            <option value="medium">Medium</option>
            <option value="high">High</option>
        </select>
    </form>
</div>
    <div class="container">
    {#each filteredCourses as course}
        <a href={course.url} target="_blank" rel="noopener noreferrer">
        <div class="row">
            <div class="text">
                <h2 class="name">{course.name}</h2>
                <p>Cost: {course.costLow}</p>
                <p>Time Commitment: {course.time}</p>
            </div>
            <div class="image">
                <img src={course.image ? `/static/${course.image}` : "/static/notebook.jpg"} alt={course.name}>
            </div>
        </div>
        </a>
    {/each}
</div>