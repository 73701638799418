<!-- src/components/Footer.svelte -->
<script>
    const year = new Date().getFullYear()
  </script>
  
  <style>
    /* Add your footer styles here */
    footer {
      background-color: #19006d;
      color: #D7D9CE;
      padding: 20px;
      text-align: center;
    }
  
  a {
    color: #fff;
    cursor: pointer;
  }
  </style>
  
  <footer>
    <p>&copy; { year } Smart English. All rights reserved.</p>
    <p>This website was designed and developed by <a href="mailto:contact@goodweb.tech">GoodWeb Designs</a></p>
  </footer>
  