<!-- src/App.svelte -->
<script>
	import Router from 'svelte-spa-router';
	import Header from './components/Header.svelte';
	import Footer from './components/Footer.svelte'
	import Blog from './routes/Blog.svelte';
	import About from './routes/About.svelte';
	import BlogPost from './routes/blogPost.svelte';
	import AllCourses from './routes/AllCourses.svelte';

	const routes = {
	'/' : AllCourses,
	'/blog': Blog,
	'/about': About,
	'/blog/:id': BlogPost,
	}
  </script>
  
 
  <main>
	<Header />
	<Router {routes} />
	<Footer />
  </main>
  